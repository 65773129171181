import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useEffect, useState } from "react"
import Logo from "../../img/LogoWhite.svg"

export default function Header() {
  const [headerTop, setHeaderTop] = useState(true)
  const [showMobileMenu, setShowMobileMenu] = useState(false)

  useEffect(() => {
    window.onscroll = function () {
      if (window.pageYOffset !== 0) {
        setHeaderTop(false)
      } else {
        setHeaderTop(true)
      }
    }
    return () => {
      window.onscroll = null
    }
  }, [])

  const toggleMobileMenu = () => setShowMobileMenu(!showMobileMenu)

  return (
    <div className={`header container-fluid ${!headerTop ? "scrolling" : ""}`}>
      <div className="header-grid container">
        <a href="/">
          <img src={Logo} className="logo" alt="SysDone Logo" />
        </a>
        <div className="nav-mobile">
          <FontAwesomeIcon
            icon={showMobileMenu ? faTimes : faBars}
            onClick={toggleMobileMenu}
          />
        </div>
        <div className={`nav-grid ${showMobileMenu ? "mobile-nav-show" : ""}`}>
          <a href="/#home-section" onClick={toggleMobileMenu}>
            Home
          </a>
          <a href="/#services" onClick={toggleMobileMenu}>
            Services
          </a>
          <a href="/#process" onClick={toggleMobileMenu}>
            Process
          </a>
          <a href="/#why" onClick={toggleMobileMenu}>
            {" "}
            Why
          </a>
          <a href="/#contact" onClick={toggleMobileMenu}>
            Let's Talk
          </a>
        </div>
      </div>
    </div>
  )
}
